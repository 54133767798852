/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import Title from 'src/components/shared/Title'
import { CenteredTitle } from 'src/components/shared/Title/CenteredTitle'
import Wrapper, { NarrowWrapper } from 'src/components/shared/Wrapper'

const PartnerForm: React.FC = () => {
  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="お問い合わせ"
        sub="CONTACT"
      />
      <NarrowWrapper>
        <CenteredTitle>
          最短3営業日で送客を開始！ <br />
          導入・維持費用無しで導入可能
        </CenteredTitle>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSenV9kLR0VASghrQsbq2kNWCwfz04R4Z1ZgcYN3U5rG5Kb70Q/viewform?embedded=true"
          width="640"
          height="1108"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          読み込んでいます…
        </iframe>
      </NarrowWrapper>
    </Wrapper>
  )
}

export default PartnerForm
